/***********************************
	15.05.2021
	AGGIUNGER FILE JAVASCRIPT SENZA DIPENDENZA JQUERY
***********************************/

// Plugin per scroll position consigliato e compatibile con bootstrap
// @link https://github.com/acch/scrollpos-styler
import 'scrollpos-styler/scrollPosStyler'; 

// Framework _app vanilla
import '../../../framework/assets/js/_app-vanilla';


// Aggiungo le data necessarie per offcanvas bs
var btnbuy = document.querySelectorAll(".btn-buy.btn-offcanvas a");
[].forEach.call(btnbuy, function(btnbuy) {
	btnbuy.setAttribute('href', '#offcanvasCTABuyNow');
	btnbuy.setAttribute('data-bs-toggle', 'offcanvas');
	btnbuy.setAttribute('role', 'button');
	btnbuy.setAttribute('aria-controls', 'offcanvasCTABuyNow');
	btnbuy.setAttribute('title', 'Buy Now');
});


var coll = document.getElementsByClassName("collapsible");
var i;

for (i = 0; i < coll.length; i++) {
  coll[i].addEventListener("click", function() {
	this.classList.toggle("active");
	var content = this.nextElementSibling;
	if (content.style.maxHeight){
	  content.style.maxHeight = null;
	} else {
	  content.style.maxHeight = content.scrollHeight + "px";
	}
  });
}