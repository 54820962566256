// Browser Detect ie https://stackoverflow.com/questions/25573883/add-class-for-different-browsers
(function () {
    for (var a = 3, b = document.createElement("div"), c = b.getElementsByTagName("i"); (b.innerHTML = "\x3c!--[if gt IE " + ++a + "]><i></i><![endif]--\x3e"), c[0]; );
    if ((a = 4 < a ? a : document.documentMode)) {
        b = " is-ie";
        for (c = 5; 12 > c; c++) a < c ? (b += " lte-ie" + c) : a > c ? (b += " gte-ie" + c) : a == c && (b += " ie" + c);
        a = { version: a };
    } else (b = " not-ie"), (a = !1);
    document.documentElement.className += b;
    window.ie = a;
})();

// Browser detect mobile https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
if (isMobile) {
 document.body.classList.add("is-mobile");
} else {
	document.body.classList.add("is-desktop");
}

/***********************************
    SCROLL OFFSET TOP
    // https://stackoverflow.com/questions/13614112/using-scrollintoview-with-a-fixed-position-header
***********************************/
let anchorLinks = document.querySelectorAll('a[href*="#"]:not([href="#"])')
for (let item of anchorLinks) {
  item.addEventListener('click', (e) => {
    let hashVal = item.getAttribute('href')
    let topOfElement = document.querySelector(hashVal).offsetTop //- 80 // offset top
    window.scroll({ top: topOfElement, behavior: "smooth" })
    //history.pushState(null, null, hashVal) // non aggiunge #hash a url
    e.preventDefault()
  })
}